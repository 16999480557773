import { SiteInfo } from "../types";
import { onImportFonts } from "./localhost";

const { indexedPaths } = require("../seo.ts");

const domainName = "qrnow.com";
const scanDomain = "qrnow.pro";
const theme = "negroni";

import { fonts } from "@/themes/negroni/fonts/fonts";

export const qrNow: SiteInfo = {
  domainName,
  siteName: "QR Now",
  siteTheme: theme,
  scanDomain: scanDomain,
  isFreeTrial: true,
  siteEmail: `info@${domainName}`,
  siteWhiteLogo: "/svg/logoFooter.svg",
  siteBlackLogo32: "/svg/logo32.svg",
  siteBlackLogo28: "/svg/logo28.svg",
  siteBlackLogoFooter: "/svg/logoFooter.svg",
  favicon: "/images/favicon-default.png",
  indexedPaths: indexedPaths[domainName],
  companies: ["starlightInnovationsLLC"],
  fonts: onImportFonts(fonts),
};
