import {
  I_LoginPage,
  I_RegisterPage,
  I_ForgotPasswordPage,
  I_ResetPasswordPage,
} from "@/data/types/pages";
import { onFormSuccess } from "@/components/Auth/types";
import { PendingAction } from "@/types/pendingActions";

export type AuthFormData = {
  login: I_LoginPage;
  register: I_RegisterPage;
  forgotPassword: I_ForgotPasswordPage;
  resetPassword: I_ResetPasswordPage;
};

export type AuthFormType = keyof AuthFormData;

export interface AuthContainerProps<T extends AuthFormType> {
  formType?: T;
  onChangeFormType?: (formType: AuthFormType) => void;
  onActionSuccess?: onFormSuccess;
  pageData: AuthFormData[T];
  pendingAction?: PendingAction;
  amplitudeEvent?: string;
}

export const DOWNLOAD_QR_COOKIE = "downloadQR";
export const QR_REFERENCE_COOKIE = "reference";
