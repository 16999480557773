"use client";

import { useState, useEffect } from "react";
import { isClient } from "@/utils/browser";
import { getDomainConfig, DomainConfig, defaultConfig } from "@/sites/config/domainConfigs";

export const useDomainConfig = (): DomainConfig => {
  const [config, setConfig] = useState<DomainConfig>(defaultConfig);

  useEffect(() => {
    if (isClient()) {
      const hostname = window.location.hostname;
      setConfig(getDomainConfig(hostname));
    }
  }, []);

  return config;
};

export const getServerDomainConfig = (hostname: string = ""): DomainConfig => {
  return getDomainConfig(hostname);
};
