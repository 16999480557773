import { SiteInfo } from "../types";
import { onImportFonts } from "./localhost";

const { indexedPaths } = require("../seo.ts");

const domainName = "fastqrgenerator.com";
const theme = "daikiri";

import { fonts } from "@/themes/daikiri/fonts/fonts";

export const fastQRGenerator: SiteInfo = {
  domainName,
  siteName: "Fast QR Generator",
  siteTheme: theme,
  isFreeTrial: true,
  siteEmail: `info@${domainName}`,
  siteWhiteLogo: "/svg/logoFooter.svg",
  siteBlackLogo32: "/svg/logo32.svg",
  siteBlackLogo28: "/svg/logo28.svg",
  siteBlackLogoFooter: "/svg/logoFooter.svg",
  favicon: "/images/favicon-default.png",
  indexedPaths: indexedPaths[domainName],
  companies: ["onworldDigitalCorporationSL", "globalOpServicesLLC", "ebdaniTradeSL"],
  fonts: onImportFonts(fonts),
};
