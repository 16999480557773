import { FilterInputOptionProps } from "@/components/FilterInput/types";
import { ReactElement } from "react";
import { AxiosResponse } from "axios";
import { Toast as ToastInterface } from "react-hot-toast";

export interface ScreenSize {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isSmallDesktop: boolean;
}

export interface UtmInterface {
  utm_source?: string;
  utm_campaign?: string;
  utm_medium?: string;
  gclid?: string;
  msclkid?: string;
  tag?: string;
}

export interface ApiResponse<T> {
  data: T | null;
  error: AxiosResponse | undefined;
  loading: boolean | null;
  request: (data?: any) => Promise<AxiosResponse<T>>;
}

export interface UseFilterProps {
  componentId: string;
  dataId?: string;
  requestHandler?: (data?: any) => Promise<any>;
}

export type UsePaginatorFiltersProps = UseFilterProps & {
  onlyPaginator?: boolean;
};

export type UseOrderFiltersProps = UseFilterProps;

export interface UseSelectorFiltersProps extends UseFilterProps {
  defaultSelectorFilters?: FilterInputOptionProps[];
}
export enum Position {
  "bottom" = "bottom-center",
  "top" = "top-center",
}

interface NotificationShared {
  marginBottom?: number;
  marginTop?: number;
  position?: Position;
  duration?: number;
  className?: string;
}
interface Custom extends NotificationShared {
  type: "custom";
  content: (t: ToastInterface) => ReactElement;
}

interface Notification extends NotificationShared {
  type: "notification";
  message: string;
}
interface Error extends NotificationShared {
  type: "error";
  message: string;
}
interface Success extends NotificationShared {
  type: "success";
  message: string;
}

export type NotificationType = Error | Success | Notification | Custom;

export type Toast = Error | Success | Notification | Custom;

export type toaster = (toast: Toast) => void;

export type TestValue = "A" | "B" | "LOADING";

export const CombinationToType = {
  "1": "A",
  "2": "B",
};
