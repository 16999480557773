export interface DomainConfig {
  requiresAuth: boolean;
  homePath: string;
  logoLinkEnabled: boolean;
  authRedirectPath: string;
  logoutRedirectPath: string;
  publicRoutes: string[];
}

const publicRoutes = [
  "/login",
  "/signup",
  "/forgot-password",
  "/reset-password",
  "/terms-and-conditions",
  "/terms-of-use",
  "/privacy-policy",
  "/cookies-policy",
  "/about-us",
  "/contact-us",
  "/how-we-are",
  "/pricing",
  "/faq",
  "/verify-payment",
];
export const defaultConfig: DomainConfig = {
  requiresAuth: false,
  homePath: "/",
  logoLinkEnabled: true,
  authRedirectPath: "/",
  logoutRedirectPath: "/",
  publicRoutes,
};

export const domainConfigs: Record<string, DomainConfig> = {
  localhost: defaultConfig,
  "app.qrnow.com": {
    requiresAuth: true,
    homePath: "/my-qr-codes",
    logoLinkEnabled: false,
    authRedirectPath: "/login",
    logoutRedirectPath: "/login",
    publicRoutes,
  },
  "app.onlineqrcode.com": {
    requiresAuth: true,
    homePath: "/qr-editor",
    logoLinkEnabled: true,
    authRedirectPath: "/qr-editor",
    logoutRedirectPath: "/qr-editor",
    publicRoutes: [...publicRoutes, "/qr-editor"],
  },
  "qrcreator.com": defaultConfig,
  "fastqrgenerator.com": defaultConfig,
};

export const getDomainConfig = (hostname: string): DomainConfig => {
  const foundConfig = Object.entries(domainConfigs).find(([domain]) =>
    hostname.includes(domain)
  )?.[1];

  return foundConfig ?? defaultConfig;
};
