import { fastQRGenerator } from "./fastqrgenerator.com";
import { localhostQRCreator } from "./localhost";
import { onlineQrCode } from "./onlineqrcode.com";
import { QRCreator } from "./qrcreator.com";
import { qrNow } from "./qrnow.com";

import { THEME } from "@/themes/types";
import { SiteInfo } from "../types";

export const DOMAINS = {
  "qrcreator.com": "qrcreator.com",
  "fastqrgenerator.com": "fastqrgenerator.com",
  "onlineqrcode.com": "onlineqrcode.com",
  "qrnow.com": "qrnow.com",
  "localhost:8001": "localhost:8001",
};

export const getLocalConfigByTheme = () => {
  const theme = process.env.NEXT_PUBLIC_THEME as THEME;
  switch (theme) {
    case "mojito":
      return QRCreator;
    case "daikiri":
      return fastQRGenerator;
    case "kalimotxo":
      return onlineQrCode;
    case "negroni":
      return qrNow;
    default:
      return QRCreator;
  }
};

export const domains: { [x: string]: SiteInfo } = {
  "qrcreator.com": QRCreator,
  "fastqrgenerator.com": fastQRGenerator,
  "onlineqrcode.com": onlineQrCode,
  "qrnow.com": qrNow,
  localhost: getLocalConfigByTheme(),
};

export type DomainsType = keyof typeof DOMAINS;
